import { Fragment, useEffect } from 'react';
import Head from 'next/head';
import CartContextProvider from '@global/CartContext';
import CompareContextProvider from '@global/CompareContext';
import ProductsContextProvider from '@global/ProductsContext';
import WishlistContextProvider from '@global/WishlistContext';
import 'react-tippy/dist/tippy.css';
import '@assets/scss/style.scss';
import client from 'src/apollo/apollo-client';
import { ApolloProvider } from '@apollo/client';
import ProductGroupsContextProvider from '@global/ProductGroups';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { pageview } from '@utils/analytics';
import 'react-floating-whatsapp-button/dist/index.css';
import ParametersContextProvider from '@global/ParametersContext';

const Wokiee = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      environment: 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      tracesSampleRate: 1.0,
    });
  }

  return (
    <Fragment>
      <Head>
        <title>San Marino</title>
      </Head>
      <ApolloProvider client={client}>
        <ParametersContextProvider>
          <CartContextProvider>
            <CompareContextProvider>
              <WishlistContextProvider>
                <ProductsContextProvider>
                  <ProductGroupsContextProvider>
                    <Component {...pageProps} />
                  </ProductGroupsContextProvider>
                </ProductsContextProvider>
              </WishlistContextProvider>
            </CompareContextProvider>
          </CartContextProvider>
        </ParametersContextProvider>
      </ApolloProvider>
    </Fragment>
  );
};

export default Wokiee;

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "apollo-link-batch-http";
import { AppConfig } from "../app-config";

const token = typeof localStorage !== "undefined" ? localStorage?.getItem("user-token") : undefined;
const headers = token ? { Authorization: `Bearer ${token}` } : {};
const link = new BatchHttpLink({ uri: AppConfig.api_url + '/graphql', headers });

const client = new ApolloClient({
  uri: AppConfig.api_url + "/graphql",
  cache: new InMemoryCache(),
  link,
});

export default client;

import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { Products } from "src/graphql/query/products";
import { formatProductGroups } from "@utils/formatProducts";
import { useMemo } from "react";
import { ProductGroups } from "src/graphql/query/product-groups";

export const ProductGroupsContext = createContext([]);

const ProductGroupsContextProvider = ({ children }) => {
  // const { data, loading } = useQuery(ProductGroups);
  //
  // const productGroupsData = useMemo(() => formatProductGroups(data), [data]);
  const loading = false;
  const productGroupsData = [];
  return (
    <ProductGroupsContext.Provider value={{ productGroups: [...productGroupsData], loading }}>
      {children}
    </ProductGroupsContext.Provider>
  );
};

export default ProductGroupsContextProvider;

import { gql } from "@apollo/client";

export const ProductGroups = gql`
  query ProductGroups {
    productGroups {
      data {
        attributes {
          thumb {
            data {
              attributes {
                url
              }
            }
          }
          products {
            left
            top
            product {
              data {
                id
                attributes {
                  thumb {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  price
                  ratings
                  sku
                  slug
                  stock
                  categories {
                    data {
                      id
                      attributes {
                        name
                        label
                      }
                    }
                  }
                  chain {
                    data {
                      id
                      attributes {
                        title
                        description
                        images {
                          data {
                            id
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  tags {
                    data {
                      attributes {
                        name
                      }
                    }
                  }
                  title
                  description
                  images {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  videos {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
